<!-- Mto expansible de Expedientes Traslados -->

<template>
  <div class="expMTrasladoMX" v-if="loaded">      
    <v-sheet class="contenedor" :elevation="0"> 

      <!-- Botonera -->
      <div class="pt-2 d-flex justify-left">         
        <baseBtraMto class="conflex"           
          :modulo="btMtoCfg" 
          :estado="estado"
          @onEvent="execAccion">        
        </baseBtraMto>
      </div>

      <!-- Controles del Mto -->
      <div style="margin-top:10px">
        <div class="conflex">
          <div class="columna" style="width:50%">
            <uiDate 
              v-model="ct.salidafhhr[2]"
              :label="ct.salidafhhr[1]"          
              type="datetime-local"
              :disabled="noEdit">          
            </uiDate> 

            <uiText
              v-bind="$input"
              v-model="ct.procedencia[2]"
              :label="ct.procedencia[1]"
              :disabled="noEdit">
            </uiText>
            
            <ctrlF                       
              :ct="ct.lugar_procedencia"
              :edicion="!noEdit">
            </ctrlF>
          </div>
            
          <div class="columna" style="width:50%">
            <uiDate 
              v-model="ct.llegadafhhr[2]"
              :label="ct.llegadafhhr[1]"          
              type="datetime-local"
              :disabled="noEdit">          
            </uiDate> 

            <uiText
              v-bind="$input"
              v-model="ct.destino[2]"
              :label="ct.destino[1]"
              :disabled="noEdit">
            </uiText>
            
            <ctrlF                       
              :ct="ct.lugar_destino"
              :edicion="!noEdit">
            </ctrlF>
          </div>
        </div>
        
        <div class="conflex">
          <v-select
            v-bind="$select"
            v-model="ct.medio[2]"
            :label="ct.medio[1]"
            :items="getMedio"
            :disabled="noEdit"
            item-value="name"
            item-text="name">            
          </v-select>
        
          <uiText
            v-bind="$input"
            v-model="ct.vuelo[2]"
            :label="ct.vuelo[1]"
            :disabled="noEdit">
          </uiText>

          <uiText
            v-bind="$input"
            v-model="ct.lta[2]"
            :label="ct.lta[1]"
            :disabled="noEdit">
          </uiText>
        </div>

        <uiObs3                       
          :record="record"
          :schema="ct"
          :schemaComponente="ct.obs"
          :edicion="!noEdit">                       
        </uiObs3>      
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseBtraMto from "@/base/baseBtraMto";  
  
  export default {
    mixins: [mixM],
    components: { baseBtraMto },
    props: {     
    },

    data() {
      return {
        stIni: {
          api: "expTrasMX",
          name:"expTrasMX",          
          titulo:"",
          recordAccess:"local",
          mView:'',
          pView:[],
          relation:'id_exp'
        },
       
      };
    },  
    

    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)"); 

      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      },

    },


    computed: {

      relationID() {
        if (!this.recordAux || !Object.keys(this.recordAux).length ) return 0;
        return this.recordAux.id;
      },


      // devuelve array con los medios de transporte
      getMedio() {
        return [
          { id: "0", name: "TERRESTRE" },
          { id: "1", name: "AEREO" },
          { id: "2", name: "MARÍTIMO" }
        ];
      },

    },

};
</script>
